import React, { useState, useEffect } from 'react'
import SwiperCore, { Navigation, Pagination, Autoplay, Controller } from 'swiper'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { breakpoints, toCssPrefix, SearchInput, ZIndex, Country, getStoredMarket, hexToRGBA } from '@ecommerce/shared'
import { MlBanner } from '../../graphql/contentfulTypes'
import { Banner } from '../Banner'
import { Icon } from '../Icon/Icon'
import { BannerDropdownButton } from '../BannerDropdownButton'
import { sendBannerEvent } from '../../utils/events'

SwiperCore.use([Navigation, Pagination, Autoplay, Controller])

const { cssPrefix, toPrefix } = toCssPrefix(`StyledSwiper__`)

interface BannerSliderProps {
  banners: MlBanner[]
  sliderActive: boolean
  hasSearchbox?: boolean
  searchInputProps?: Parameters<typeof SearchInput>[0] & { 'data-test': string }
  country: Country
}

const StyledSwiper = styled(Swiper)<{ hasSearchbox?: boolean }>`
  .swiper {
    &-button {
      &-next,
      &-prev {
        display: none;
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 200ms;
        &::after {
          font-weight: bolder;
          font-size: 18px;
        }
        &:hover {
          opacity: 0.5;
          background-color: ${({ theme }) => theme.colors.red};
        }
      }
    }
    &-pagination {
      margin-bottom: ${({ hasSearchbox }) => (hasSearchbox ? '20px' : '0')};
      max-width: 200px;
      left: calc(50% - 95px);
      &-bullet {
        height: 15px;
        width: 15px;
        &-active {
          background: ${({ theme }) => theme.colors.red};
          opacity: 0.75;
        }
      }
    }
  }
  .${cssPrefix} {
    &search-input {
      border: 1px solid red;
      position: absolute;
      min-width: 75%;
      max-width: 100%;
      width: 75%;
    }
    &dropdown-button {
      position: absolute;
      top: 8px;
      &-left {
        left: 8px;
      }
      &-right {
        right: 16px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .swiper- {
      &button- {
        &next,
        &prev {
          display: flex;
        }
      }
      &-pagination {
        &-bullet {
          height: 15px;
          width: 15px;
        }
      }
    }
    .${cssPrefix} {
      &dropdown-button {
        top: 337px;
      }
    }
  }
`

const SearchInputContainer = styled.div`
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  .${cssPrefix} {
    &search-input {
      width: 75vw;
      min-width: 300px;
      max-width: 100vw;
      margin-top: -20px;
      z-index: ${ZIndex.low};
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &search-input {
        max-width: 500px;
      }
    }
  }
`

const dropdownAnimationMS = 500

const DropdownContent = styled.div<{ active: boolean; hasSearchbox?: boolean }>`
  width: 100%;
  transition: all ${dropdownAnimationMS / 1000}s ease-in-out;
  overflow: hidden;
  background-color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  color: ${({ theme }) => theme.colors.white};
  max-height: ${({ active }) => (active ? '500px' : '0')};
  text-align: justify;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: inherit;
  }

  .${cssPrefix} {
    &text-container {
      flex-grow: 1;
      margin-top: ${({ hasSearchbox }) => (hasSearchbox ? '35px' : '25px')};
      margin-bottom: 25px;
      font-size: 12px;
      max-width: 1025px;
      line-height: 180%;
      transition: opacity ${dropdownAnimationMS / 1000}s ease-in-out;
      opacity: ${({ active }) => (active ? '1' : '0')};
    }
    &dropdown-content-icon {
      width: 20px;
      transition: all ${dropdownAnimationMS / 1000}s ease-in-out;
      max-height: ${({ active }) => (active ? '40px' : '0')};
      margin-right: 20px;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &text-container {
        font-size: 14px;
      }
      &dropdown-content-icon {
        min-width: 30px;
        margin-right: 20px;
      }
    }
  }
`

export const BannerSlider = ({ banners, hasSearchbox, searchInputProps, country, sliderActive }: BannerSliderProps) => {
  const currentBanners = banners.map((banner, index) => ({ ...banner, dropdownIsOpen: false, index }))

  const [controller, setController] = useState<SwiperCore>()
  const [activeBanner, setActiveBanner] = useState(currentBanners[0])

  const transitionStart = () => {
    setActiveBanner({ ...activeBanner, dropdownIsOpen: false })
  }
  const transitionEnd = (swiper: SwiperCore) => setActiveBanner(currentBanners[swiper.realIndex])

  useEffect(() => {
    if (controller) {
      controller.off('slideChangeTransitionStart')
      controller.off('slideChangeTransitionEnd')
      controller.on('slideChangeTransitionStart', transitionStart)
      controller.on('slideChangeTransitionEnd', transitionEnd)
    }
  }, [controller, activeBanner.index])

  useEffect(() => {
    if (controller?.autoplay) {
      if (activeBanner.dropdownIsOpen) controller.autoplay.stop()
      else controller.autoplay.start()
    }
  }, [activeBanner.dropdownIsOpen])

  const onDropdownButtonClick = () => {
    if (!activeBanner.dropdownIsOpen && activeBanner?.index !== undefined && activeBanner?.name) {
      sendBannerEvent(`Términos y condiciones ${activeBanner.name}`, activeBanner.index + 1)
    }

    setActiveBanner({ ...activeBanner, dropdownIsOpen: !activeBanner.dropdownIsOpen })
  }

  return (
    <>
      <StyledSwiper
        cssMode={!sliderActive}
        slidesPerView={1}
        speed={dropdownAnimationMS}
        loop={sliderActive}
        navigation={sliderActive}
        scrollbar={false}
        pagination={sliderActive ? { type: 'bullets', clickable: true } : { type: 'custom', clickable: false }}
        autoplay={{ delay: 7000 }}
        onSwiper={setController}
        hasSearchbox={hasSearchbox}
      >
        {currentBanners.map((currentBanner, i) => (
          <SwiperSlide key={`slide-${i}`}>
            <Banner {...currentBanner} index={i} country={country} />
            {currentBanner.dropdownContent && (
              <BannerDropdownButton
                className={`${toPrefix('dropdown-button')} ${toPrefix(
                  `dropdown-button-${currentBanner.dropdownButtonPosition === 'left' ? 'left' : 'right'}`,
                )}`}
                text={
                  currentBanner.dropdownButtonText
                    ? documentToReactComponents(currentBanner.dropdownButtonText.json)
                    : undefined
                }
                isOpen={(currentBanner.index === activeBanner.index && activeBanner.dropdownIsOpen) ?? false}
                onClick={onDropdownButtonClick}
              />
            )}
          </SwiperSlide>
        ))}
      </StyledSwiper>
      {hasSearchbox && (
        <SearchInputContainer>
          <SearchInput {...(searchInputProps ?? {})} className={toPrefix('search-input')} />
        </SearchInputContainer>
      )}
      <DropdownContent
        active={activeBanner.dropdownIsOpen ?? false}
        className={toPrefix('dropdown-content')}
        hasSearchbox={hasSearchbox}
      >
        <Icon iconId="info_alt" fillColor="grey40" size="32" className={toPrefix('dropdown-content-icon')} />
        <div className={toPrefix('text-container')}>
          {activeBanner.dropdownContent?.json &&
            documentToReactComponents(activeBanner.dropdownContent.json, {
              renderNode: {
                [INLINES.HYPERLINK]: (node, child) => (
                  <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>
                    {child}
                  </a>
                ),
              },
            })}
        </div>
      </DropdownContent>
    </>
  )
}
