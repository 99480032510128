import React, { HTMLAttributes } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'
import { toCssPrefix, breakpoints, toNumber } from '@ecommerce/shared'
import { BannerDiscountLabelColor } from '../../graphql/contentfulTypes'

const { cssPrefix, toPrefix } = toCssPrefix('BannerDiscountLabel__')

const defaultWidth = 152

const getSize = (pxSize: number) => ({ scaleWidth = `${defaultWidth}px` }: { scaleWidth?: string }) => {
  return `calc(${scaleWidth} * ${pxSize} / ${defaultWidth})`
}

interface WrapperProps {
  labelColor: BannerDiscountLabelColor
  limitWidth: boolean
  scaleWidth?: string
}

const toLimitedWidthScale = (width: number) => ({ limitWidth }: WrapperProps) => {
  if (limitWidth) return `width: ${getSize(width)};`
}

const toLimitedWidth = (width: string) => ({ limitWidth }: WrapperProps) => {
  if (limitWidth) return `width: ${width};`
}

const getLabelColorStyles = ({ theme, labelColor }: { theme: any; labelColor: BannerDiscountLabelColor }) => {
  const returnBackgroundColor = (color: string) => `background: ${color};`
  const returnBorderColor = (color: string) => `border: 2px solid ${color};`
  const returnBoxShadow = (color: string) => `box-shadow: 0px 0px 8px 5px ${color}, inset 0px 0px 8px 5px ${color};`
  switch (labelColor) {
    case 'red': {
      return css`
        ${returnBackgroundColor(theme.colors.red)}
      `
    }
    case 'red-neon': {
      return css`
        ${returnBackgroundColor(theme.colors.red)}
        ${returnBoxShadow('rgba(255, 113, 141, 0.49)')}
      `
    }
    case 'green-neon': {
      return css`
        ${returnBackgroundColor('#12C269')}
        ${returnBoxShadow('rgba(18, 194, 105m 0.49)')}
      `
    }
    case 'blue-neon': {
      return css`
        ${returnBackgroundColor('#2CB2DD')}
        ${returnBoxShadow('rgba(86, 204, 242, 0.49)')}
      `
    }
    case 'red-neon-outline': {
      return css`
        ${returnBorderColor(theme.colors.red)}
        ${returnBoxShadow('rgba(249, 63, 65, 0.54)')}
      `
    }
    case 'pink-neon-outline': {
      return css`
        ${returnBorderColor('#DD2F21')}
        ${returnBoxShadow('rgba(255, 113, 141, 0.49)')}
      `
    }
    case 'yellow': {
      return css`
        ${returnBackgroundColor('rgba(255, 214, 66,1)')}
        color: rgba(22, 29, 36, 1);
      `
    }
    default: {
      return css`
        ${returnBackgroundColor(theme.colors[labelColor])}
      `
    }
  }
}

const Wrapper = styled.div<WrapperProps>`
  ${toLimitedWidthScale(152)}
  height: ${getSize(64)};
  color: ${({ theme }) => theme.colors.white};
  ${getLabelColorStyles}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ scaleWidth }) => [10, 8, 8, 8].map((size) => getSize(size)({ scaleWidth })).join(' ')};
  .${cssPrefix} {
    &side-text {
      height: ${getSize(48)};
      width: ${getSize(16)};
      font-weight: bold;
      text-orientation: upright;
      span {
        height: ${getSize(48)};
        width: ${getSize(16)};
        transform: rotate(-90deg);
        display: block;
        font-size: ${getSize(12)};
        margin-left: ${getSize(15)};
        margin-top: ${getSize(13)};
        text-align: center;
      }
    }
    &currency-symbol {
      font-size: ${getSize(42)};
      font-weight: bold;
    }
    &discount-amount {
      font-size: ${getSize(62)};
      height: ${getSize(48)};
      ${toLimitedWidthScale(80)}
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: ${getSize(3)};
      padding-right: ${getSize(4)};
      font-weight: bold;
      letter-spacing: ${getSize(-5)};
      text-align: right;
    }
    &discount-text {
      width: ${getSize(40)};
      height: ${getSize(48)};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: ${getSize(12)};
      &-percent {
        height: ${getSize(32)};
        font-size: ${getSize(36)};
        padding: 0;
        margin-top: ${getSize(-6)};
        text-align: center;
      }
      &-label {
        text-align: center;
        font-size: ${getSize(12)};
        font-weight: bold;
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    ${toLimitedWidth('152px')}
    height: 64px;
    padding: 10px 8px 8px 8px;
    .${cssPrefix} {
      &side-text {
        height: 48px;
        width: 16px;
        span {
          height: 48px;
          width: 16px;
          font-size: 12px;
          margin-left: 15px;
          margin-top: 13px;
        }
      }
      &currency-symbol {
        font-size: 42px;
        font-weight: bold;
      }
      &discount-amount {
        font-size: 62px;
        height: 48px;
        ${toLimitedWidth('80px')}
        padding-top: 3px;
        padding-right: 4px;
        letter-spacing: -5px;
        &-label {
          ${toLimitedWidth('80px')}
        }
      }
      &discount-text {
        width: 40px;
        height: 48px;
        font-size: 12px;
        &-percent {
          height: 32px;
          font-size: 36px;
          margin-top: -6px;
        }
        &-label {
          font-size: 12px;
        }
      }
    }
  }
`

interface BannerDiscountLabelProps extends HTMLAttributes<HTMLDivElement> {
  labelColor: BannerDiscountLabelColor
  discount: number
  discountType: 'amount' | 'percentage'
  currencySymbol: 'Bs' | '$'
  sideText?: 'DESDE' | 'HASTA' | 'EXTRA'
}

export const BannerDiscountLabel = ({
  labelColor,
  discount,
  sideText,
  discountType,
  currencySymbol,
  ...restProps
}: BannerDiscountLabelProps) => {
  return (
    <Wrapper {...{ labelColor, limitWidth: discountType === 'percentage', ...restProps, scaleWidth: '22.2vw' }}>
      {sideText && <div className={toPrefix('side-text')}>{sideText && <span>{sideText}</span>}</div>}
      {discountType === 'amount' && <div className={toPrefix('currency-symbol')}>{currencySymbol}</div>}
      <div className={toPrefix('discount-amount')}>{toNumber(discount, currencySymbol === 'Bs')}</div>
      {discountType === 'percentage' && (
        <div className={toPrefix('discount-text')}>
          <div className={toPrefix('discount-text-percent')}>%</div>
          <div className={toPrefix('discount-text-label')}>DCTO</div>
        </div>
      )}
    </Wrapper>
  )
}
