import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoints, useLocation, getStoredMarket } from '@ecommerce/shared'
import { BannerSlider } from './BannerSlider'
import bannerSliderQuery, { QueryBanner, BannerSliderQueryResult, toMlBanner } from '../../graphql/bannerSliderQuery'
import { GoogleTagManagerEventType } from '../../graphql/contentfulTypes'
import useQuery from '../../hooks/useQuery'
import SliderSkeleton from '../Skeletons/SliderSkeleton'
import { sendViewPromotionEvent } from '../../utils/events'

const WidgetWrapper = styled.div`
  min-height: 375px;
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: 375px;
  }
  @media (${breakpoints.desktop.min}) {
    min-height: 400px;
  }
`

interface WidgetStateData {
  title?: string
  type: 'banner-slider'
  items: QueryBanner[]
}

interface BannerSliderWidgetProps {
  listId: string
}

export const BannerSliderWidget = ({ listId }: BannerSliderWidgetProps) => {
  const [bannersData, setBannersData] = useState<WidgetStateData>({
    type: 'banner-slider',
    items: [],
  })
  const [isLoading, setLoading] = useState(true)
  const [renderLists, setRenderLists] = useState(true)

  const { data, loading, error } = useQuery<BannerSliderQueryResult>(bannerSliderQuery, { variables: { listId } })
  const currentMarket = getStoredMarket()
  useEffect(() => {
    if (data && !loading) {
      const block = data?.orList

      if (!block) return setRenderLists(false)

      const banners = block.itemsCollection.items.filter((banner) =>
        banner.locationsCollection.items?.find((location) => location.slug === currentMarket?.slug),
      )

      setBannersData({
        type: block?.blockType ?? 'banner-slider',
        items: banners ?? [],
        title: block?.title,
      })
      setLoading(false)
    }
  }, [data, loading])

  useEffect(() => {
    const promotionItems =
      data?.orList.itemsCollection.items.filter(
        (item) =>
          item.googleTagManagerEventType === GoogleTagManagerEventType.PROMOTION && item.googleTagManagerEventValue,
      ) ?? []
    if (data && !isLoading && promotionItems.length) {
      sendViewPromotionEvent(
        promotionItems.map((item) => ({
          item_id: item.googleTagManagerEventValue ?? '',
          item_name: item.googleTagManagerEventValue ?? '',
          creative_name: item.name,
        })),
      )
    }
  }, [isLoading, data])

  const {
    state: { country },
  } = useLocation()

  if (!renderLists || error !== undefined || (bannersData.items.length === 0 && !isLoading)) return null
  return (
    <WidgetWrapper>
      {isLoading ? (
        <SliderSkeleton />
      ) : (
        <BannerSlider
          sliderActive={data?.orList.sliderActive ?? true}
          country={country}
          banners={bannersData.items.map(toMlBanner)}
        />
      )}
    </WidgetWrapper>
  )
}
