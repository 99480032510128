import styled from 'styled-components'
import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'
import { ClientSideMlTextWithIcon } from '../../graphql/contentfulTypes'

const WrapperStep = styled.div<{ textColor?: string }>`
  display: flex;
  align-items: center;
  margin: 0 auto 30px;
  width: 100%;
  height: 100%;
  max-width: 316px;

  img {
    width: 40px;
    height: 40px;
  }

  .shape {
    background: ${({ theme }) => theme.colors.platinum80};
    border-radius: 100%;
    font-size: 20px;
    line-height: 42px;
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    font-weight: bold;
    justify-content: center;
  }

  .label {
    color: ${({ textColor, theme }) => textColor || theme.colors.bodyText};
    font-size: 17px;
    line-height: 25px;
    text-align: left;
    margin-left: 16px;
    letter-spacing: -0.24px;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    .label {
      margin-left: 25px;
    }
  }

  @media (${breakpoints.desktop.min}) {
    img {
      width: 80px;
      height: 80px;
    }

    .shape {
      width: 80px;
      height: 80px;
      font-size: 40px;
    }

    .label {
      width: 176px;
      margin-left: 31px;
    }
  }
`

type Props = {
  item: ClientSideMlTextWithIcon
  index: number
}

const Step = ({ item, index }: Props) => {
  return (
    <WrapperStep textColor={item.textColor} key={item.title}>
      {item.icon?.url ? (
        <img src={item.icon.url} alt={`step ${index + 1}`} />
      ) : (
        <div className="shape">{index + 1}</div>
      )}
      <div className="label">{documentToReactComponents(item.text.json)}</div>
    </WrapperStep>
  )
}

export default Step
