import React from 'react'
import styled from 'styled-components'
import { breakpoints, skeletonAnimationStyles } from '@ecommerce/shared'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: ${100 / (784 / 521)}vw;

  padding: none;

  .info-section {
    padding: 0 35px;
    display: grid;
    width: 100%;
    row-gap: 12px;
  }

  .rectangule {
    &-large,
    &-medium,
    &-small,
    &-thin {
      border-radius: 4px;
    }

    &-large {
      width: 70%;
      height: 31px;
    }
    &-medium {
      width: 60%;
      height: 21px;
    }
    &-small {
      width: 60%;
      height: 26px;
    }
    &-thin {
      width: 70%;
      height: 14px;
    }
  }

  .circle {
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }

  position: relative;
  float: left;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: #ffffff;
  z-index: 10;
  opacity: 1;

  .animated-background {
    ${skeletonAnimationStyles}
  }

  @media (${breakpoints.tabletPortrait.min}) {
    height: 375px;

    .info-section {
      padding: 0 56px;
    }

    .circle {
      height: 224px;
      width: 224px;
    }

    .rectangule {
      &-large {
        width: 70%;
        height: 56px;
      }
      &-medium {
        width: 60%;
        height: 38px;
      }
      &-small {
        width: 40%;
        height: 47px;
      }
      &-thin {
        width: 50%;
        height: 25px;
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    padding: 0 0 0 0;
    height: 504px;

    .info-section {
      padding: 0 20px 0 99px;
    }

    .circle {
      height: 258px;
      width: 258px;
    }

    .rectangule {
      &-large {
        width: 70%;
        height: 58px;
      }
      &-medium {
        width: 70%;
        height: 40px;
      }
      &-small {
        width: 30%;
        height: 48px;
      }
      &-thin {
        width: 50%;
        height: 27px;
      }
    }
  }
`

const SliderSkeleton = () => {
  return (
    <Wrapper>
      <div className="info-section">
        <div className="animated-background rectangule-large" />
        <div className="animated-background rectangule-medium" />
        <div className="animated-background rectangule-small" />
        <div className="animated-background rectangule-thin" />
      </div>
      <div className="animated-background circle" />
    </Wrapper>
  )
}

export default SliderSkeleton
