import { ClientSideMlTextWithIcon, BlockType, BlockTitle } from './contentfulTypes'
import { OrListFragment, MlTextWithIconFragment } from './fragments'

export interface QueryTextWithIconResult {
  orList: {
    title: string
    blockType: BlockType.TEXT_WITH_ICON
    itemsCollection: {
      items: ClientSideMlTextWithIcon[]
    }
  } & Omit<BlockTitle, 'title'>
}

export default `
  query TextWithIconQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ...MlTextWithIconFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${MlTextWithIconFragment}
`
