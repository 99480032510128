import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import { ratio } from './bannerRatios'

const ImageColumn = styled.div`
  user-select: none;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 375px;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    height: 375px;
    img {
      max-height: 375px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    height: 400px;
    img {
      max-height: 400px;
    }
  }
`

interface BannerImageColumnProps extends HTMLAttributes<HTMLDivElement> {
  images: { mobile: string; tablet: string; desktop: string }
}

export const BannerImageColumn = ({ images, ...restProps }: BannerImageColumnProps) => {
  return (
    <ImageColumn {...restProps} draggable={false}>
      <picture draggable={false}>
        <source media="( max-width: 667px )" srcSet={images.mobile} draggable={false} />
        <source media="( max-width: 1024px )" srcSet={images.tablet} draggable={false} />
        <source media="( min-width: 1025px )" srcSet={images.desktop} draggable={false} />
        <img src={images.desktop} alt="" draggable={false} />
      </picture>
    </ImageColumn>
  )
}
