import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { toCssPrefix, Anchor, breakpoints } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('BannerDropdownButton__')

const defaultWidth = 257
const mobileScaleWdith = '37.53vw'

const getSize = (pxSize: number) => ({ scaleWidth = mobileScaleWdith }: { scaleWidth?: string }) => {
  return `calc(${scaleWidth} * ${pxSize} / ${defaultWidth})`
}

const Wrapper = styled.div<{ scaleWidth?: string }>`
  border-radius: ${getSize(24)};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${() => [0, 16, 0, 20].map((size) => getSize(size)({ scaleWidth: mobileScaleWdith })).join(' ')};
  letter-spacing: ${getSize(-0.5)};
  .${cssPrefix} {
    &label {
      text-align: center;
      font-size: ${getSize(17)};
      margin-right: 5px;
    }
    &chevron {
      width: ${getSize(14)};
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    border-radius: 24px;
    padding: 0 16px 0 20px;
    letter-spacing: -0.5px;
    .${cssPrefix} {
      &label {
        font-size: 12px;
      }
      &chevron {
        width: 14px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &label {
        font-size: 14px;
      }
      &chevron {
        width: 14px;
      }
    }
  }
`

interface BannerDropdownButtonProps {
  isOpen: boolean
  text?: React.ReactNode
}

export const BannerDropdownButton = ({
  isOpen,
  text = 'Términos y condiciones',
  ...restProps
}: HTMLAttributes<HTMLButtonElement> & BannerDropdownButtonProps) => {
  const iconId = isOpen ? 'chevron_up' : 'chevron_down'
  return (
    <Anchor {...restProps}>
      <Wrapper>
        <span className={toPrefix('label')}>{text}</span>
        <Icon iconId={iconId} size={18} fillColor="green" className={toPrefix('chevron')} />
      </Wrapper>
    </Anchor>
  )
}

export { cssPrefix }
