import {
  MlBanner,
  RichTextJson,
  ContentfulImage,
  BannerDiscountLabelColor,
  TagManagerEventData,
} from './contentfulTypes'

type TextColor = 'red' | 'white' | 'black' | 'blue' | 'green'
type ClientSideContentfulImage = { id: string; url: string }
type Locations = {
  title: string
  slug: string
}

export type QueryBanner = {
  textAlignment: 'left' | 'right' | 'center'
  contentAlignment: 'end' | 'start' | 'center'
  locationsCollection: { items?: Locations[] }
  referentialPrice: RichTextJson
  referentialPriceColor: TextColor
  referentialPriceStrikethrough: boolean
  discount?: number
  discountType?: 'amount' | 'percentage'
  discountLabelText?: 'DESDE' | 'HASTA' | 'EXTRA'
  discountLabelColor?: BannerDiscountLabelColor
  preTitle: RichTextJson
  preTitleColor?: TextColor
  subtitleBanner: RichTextJson
  subtitleColor?: TextColor
  titleBanner: RichTextJson
  titleColor?: TextColor
  promotionalCode: RichTextJson
  promotionalCodeColor?: TextColor
  promotionalCodeBackgroundColor?: TextColor
  alcoholicBeverageBlock: string
  hashtag?: RichTextJson
  hashtagColor?: TextColor
  actionSlugBanner: string
  buttonColor: 'red' | 'white' | 'black' | 'white-red' | 'blue'
  buttonTextBanner: RichTextJson
  buttonType: 'default' | 'outline' | 'text'
  name: string
  layout: 'text-image' | 'image-text-image' | 'image-text'
  backgroundImageDesktop: ClientSideContentfulImage
  backgroundImageMobile: ClientSideContentfulImage
  backgroundImageTablet: ClientSideContentfulImage
  productImagesDesktopCollection: { items: ClientSideContentfulImage[] }
  productImageTablet: ClientSideContentfulImage
  productImageMobile: ClientSideContentfulImage
  dropdownButtonText?: RichTextJson
  dropdownButtonPosition?: 'left' | 'right'
  dropdownContent?: RichTextJson
  sys: {
    id: string
  }
} & TagManagerEventData

export interface BannerSliderQueryResult {
  orList: {
    title: string
    blockType: 'banner-slider'
    sliderActive: boolean
    itemsCollection: {
      items: QueryBanner[]
    }
  }
}

export const toContentfulImage = (image: ClientSideContentfulImage): ContentfulImage => ({
  file: { url: image.url },
  id: image.id,
})

export const toMlBanner = (queryBanner: QueryBanner): MlBanner => {
  return {
    ...queryBanner,
    id: queryBanner.sys.id,
    actionSlugBanner: { actionSlug: queryBanner.actionSlugBanner },
    backgroundImageDesktop: toContentfulImage(queryBanner.backgroundImageDesktop),
    backgroundImageMobile: toContentfulImage(queryBanner.backgroundImageMobile),
    backgroundImageTablet: toContentfulImage(queryBanner.backgroundImageTablet),
    productImagesDesktop: queryBanner.productImagesDesktopCollection.items.map(toContentfulImage),
    productImageTablet: toContentfulImage(queryBanner.productImageTablet),
    productImageMobile: toContentfulImage(queryBanner.productImageMobile),
    googleTagManagerEventType: queryBanner.googleTagManagerEventType,
    googleTagManagerEventValue: queryBanner.googleTagManagerEventValue,
  }
}

export default `
  query BannerSliderQuery($listId: String!) {
    orList(id: $listId) {
      title
      blockType
      sliderActive
      itemsCollection {
        items {
          ... on MlBanner {
            actionSlugBanner: actionSlug
            locationsCollection {
              items {
                title
                slug
              }
            }
            backgroundImageDesktop {
              sys{
                id
              }
              url
            }
            backgroundImageMobile {
              sys{
                id
              }
              url
            }
            backgroundImageTablet {
              sys{
                id
              }
              url
            }
            buttonColor
            buttonTextBanner: buttonText {
              json
            }
            buttonType
            dropdownButtonText {
              json
            }
            dropdownButtonPosition
            dropdownContent {
              json
            }
            layout
            name
            productImagesDesktopCollection{
              items{
                sys{
                  id
                }
                url
              }
            }
            productImageMobile {
              url
            }
            preTitle {
              json
            }
            subtitleBanner: subtitle {
              json
            }
            titleBanner: title {
              json
            }
            promotionalCode {
              json
            }
            hashtag {
              json
            }
            hashtagColor
            preTitleColor
            productImageTablet {
              sys{
                id
              }
              url
            }
            subtitleColor
            titleColor
            promotionalCodeColor
            promotionalCodeBackgroundColor
            referentialPrice {
              json
            }
            referentialPriceColor
            referentialPriceStrikethrough
            textAlignment
            contentAlignment
            alcoholicBeverageBlock
            discount
            discountType
            discountLabelText
            discountLabelColor
            googleTagManagerEventType
            googleTagManagerEventValue
            sys {
              id
            }
          }
        }
      }
    }
  }
`
