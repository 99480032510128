import React from 'react'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import styled from 'styled-components'
import useQuery from '../../hooks/useQuery'
import textWithIconQuery, { QueryTextWithIconResult } from '../../graphql/textWithIconQuery'
import Step from '../Step'
import SectionTitle from '../Moments/SectionTitle'

const { cssPrefix, toPrefix } = toCssPrefix('StepsWrapper__')

const WrapperSteps = styled.div<{ textColor?: string; backgroundColor?: string }>`
  .${cssPrefix} {
    &header {
      font-size: 24px;
      line-height: 31px;
      color: ${({ theme }) => theme.colors.bodyText};
      font-weight: bold;
      margin: 0 auto 30px auto;
      position: relative;
      max-width: 70%;

      :after {
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - 50px);
        bottom: -8px;
        width: 100px;
        height: 6px;
        background: #ffffff;
      }
    }
    &content {
      background: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.background.bodyAlternative};
      &-steps {
        display: flex;
        justify-content: space-around;
        padding: 25px 30px 5px 30px;
        flex-direction: column;
        margin: 0 auto 34px;
        width: fit-content;
      }

      @media (${breakpoints.desktop.min}) {
        &-steps {
          flex-direction: row;
          width: 100%;
          max-width: 1200px;
          align-items: center;
        }
      }
    }
  }
`

type Props = {
  listId: string
  backgroundColor?: string
}

const Steps = ({ listId, backgroundColor }: Props) => {
  const { data, loading, error } = useQuery<QueryTextWithIconResult>(textWithIconQuery, { variables: { listId } })
  const orList = data?.orList
  const steps = orList?.itemsCollection?.items

  if (error !== undefined || (!steps?.length && !loading)) return null

  return (
    <WrapperSteps backgroundColor={backgroundColor} data-testid="steps">
      {orList?.title && <SectionTitle color={orList?.titleColor} text={orList?.title} {...orList} />}
      <div className={toPrefix('content')}>
        <div className={toPrefix('content-steps')}>
          {steps?.map((item, index) => (
            <Step key={`${index}-${item.title}`} item={item} index={index} />
          ))}
        </div>
      </div>
    </WrapperSteps>
  )
}

export default Steps
